@keyframes flash {
	60% { opacity: 1; } 
	70% { opacity: .2; } 
	80% { opacity: .6; } 
	90% { opacity: .2; } 
	100% { opacity: 1; }
}
.room-tiles {
  position: relative;
  height: 100%;

  // $gapFromTop: 3%;
  $gapHeight: 1.2%;
  $gapWidth: 3.7%;
  $gapHallwayIndicatorWidth: 81%;
  $gapHallwayWidth: 14%;

  $gapHallwayIndicatorHeight: 10%;
  $gapHallwayHeight: 15%;
  
  $longSidesLeft: 3%;
  $longSidesRight: 3%;

  $longSidesWidth: 30.5%;
  $longSidesWidth2: 15%;

  $longSidesHeight: 19.5%;
  $longSidesMapSectionHeight: 24%;

  $mikvahSquareWidth: 30%;
  $mikvahSquareWidth2: 15%;
  $mikvahSquareHeight: 38%;

  .room-availability{
    .items {
      flex-wrap: wrap;
    }
  }
  .mapSections{
    display: flex;
    height: 70%;
    position: relative;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    &.activeSec{
      height: 100%;
    }
    .mapSection{
      position: relative;
      height: 100%;
    }
    .section-1{ height:100%; width: 48%; margin-inline-start: 0%;}
    .section-2{ height:100%; width: 48%; margin-inline-start: 0% }
    .section-3{ height:40%; width: 31%; top: 0; margin-inline-start: 0%; position: absolute; right: 17.8%;}
    
    &.activeSec1{
      .section-1{height:100%; width: 100%;}
      .section-2{height:100%; width: 100%;}
      .section-3{height:30%; width: 100%;right: 0;}
    }
    &.activeSec2{
      .section-1{ height: 50%; width: 100%;}
      .section-2{ height: 50%; width: 100%;}
      .section-3{ height: 15%; width: 100%;right: 0;}
    }
    &.activeSec3{
      .section-1{height:40%; width: 100%;}
      .section-2{height:40%; width: 100%;}
      .section-3{height:15%; width: 100%;right: 0;position: relative;}
    }
    &.activeSec{
      .room-tile-17 { height: 100%; width: 48.4%; right: 51.5%; top: 0;}
      .room-tile-mikvah-3 { height: 100%; width: 48.4%; left: unset; right: 0%; top: 0%;  }
      .room-tile{
        &.room-tile-1 { height: $longSidesMapSectionHeight; top: (0 * ($longSidesMapSectionHeight + $gapHeight)) }
        &.room-tile-2 { height: $longSidesMapSectionHeight; top: (1 * ($longSidesMapSectionHeight + $gapHeight)) }
        &.room-tile-3 { height: $longSidesMapSectionHeight; top: (2 * ($longSidesMapSectionHeight + $gapHeight)) }
        &.room-tile-4 { height: $longSidesMapSectionHeight; top: (3 * ($longSidesMapSectionHeight + $gapHeight)) }
        &.room-tile-5 { height: $longSidesMapSectionHeight; top: (3 * ($longSidesMapSectionHeight + $gapHeight)) }
        &.room-tile-6 { height: $longSidesMapSectionHeight; top: (3 * ($longSidesMapSectionHeight + $gapHeight)) }
        &.room-tile-7 { height: $longSidesMapSectionHeight; top: (2 * ($longSidesMapSectionHeight + $gapHeight)) }
        &.room-tile-8 { height: $longSidesMapSectionHeight; top: (1 * ($longSidesMapSectionHeight + $gapHeight)) }
  
        &.room-tile-9 { height: $longSidesMapSectionHeight; top: (0 * ($longSidesMapSectionHeight + $gapHeight))}
        &.room-tile-10 { height: $longSidesMapSectionHeight; top: (1 * ($longSidesMapSectionHeight + $gapHeight))}
        &.room-tile-11 { height: $longSidesMapSectionHeight; top: (2 * ($longSidesMapSectionHeight + $gapHeight))}
        &.room-tile-12 { height: $longSidesMapSectionHeight; top: (3 * ($longSidesMapSectionHeight + $gapHeight))}
        &.room-tile-13 { height: $longSidesMapSectionHeight; top: (3 * ($longSidesMapSectionHeight + $gapHeight))}
        &.room-tile-14 { height: $longSidesMapSectionHeight; top: (2 * ($longSidesMapSectionHeight + $gapHeight))}
        &.room-tile-15 { height: $longSidesMapSectionHeight; top: (1 * ($longSidesMapSectionHeight + $gapHeight))}
        &.room-tile-16 { height: $longSidesMapSectionHeight; top: (0 * ($longSidesMapSectionHeight + $gapHeight))}
  
        &.room-tile-mikvah-1 { right: (1 * ($longSidesWidth + $gapWidth)); top: (1 * ($longSidesMapSectionHeight + $gapHeight)); }
        &.room-tile-mikvah-2 { right: (1 * ($longSidesWidth + $gapWidth)); top: (1 * ($longSidesMapSectionHeight + $gapHeight)); }  
    
      }
    }

  }

  .room-tile {
    background: rgba(0,0,0,.1);
    padding: 8px;
    //width: 10%;
    //height: 10%;
    position: absolute;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border: 2px solid transparent;

    .header {
      display: flex;
      line-height: 1;
      justify-content: space-between;
      position: absolute;
      width: 94%;
      .number {
        font-weight: bold;
        font-size: 28px;
      }
      .icon {
      }
    }
    .reservation {
      text-align: center;
      border: 1px solid $main-color-grey;
      border-radius: 4px;
      display: inline-block;
      margin: 3px auto;
      padding: 3px 6px;
      .icon {
        margin: 4px 3px 0;
      }
      .icon-excluded{
        margin: 4px 3px 0;
        height: 16px;
        display: inline;
        div{
          display: inline;
        }
        svg{
          height: 16px;
          display: inline;
          .cls-1{
            fill: $text-color;
            stroke: $text-color;
          }
        }
      }
    }
    .body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-content: center;
      text-align: center;
      .emergency{
        svg{
          height: 30px;
          padding-bottom: 5px;
        }
        .cls-1, .cls-2{
          stroke: $main-color-red;
        }
        animation: flash ease-in-out 2s infinite;
      }
      .timer {
        margin-bottom: 5px;
        display: block;
        &.over-time {
          color: $main-color-red;
          animation: flash ease-in-out 2s infinite;
        }
        .icon {
          margin-inline-end: 4px;
        }
      }
      .status {
        background: $main-color-grey;
        text-transform: uppercase;
        color: #fff;
        border-radius: 8px;
        text-align: center;
        font-size: 14px;
      }
    }

    @each $statusColor, $statusColorValue in $statusColors {
      &.status-color-#{$statusColor} {
        background: rgba($statusColorValue, .2);
        .reservation {
          border-color: #{$statusColorValue};
          background-color: rgba($statusColorValue, .2);
        }
        .status {
          background: #{$statusColorValue};
        }
      }
    }

    &.recentlyUpdatedIn{
      border-color: $text-color-light;
      transition: border 1.5s;
    }
    &.recentlyUpdatedOut{
      border-color: transparent;
      transition: border 1.5s;
    }

    &.no-attendant {
      opacity: .3;
    }


    &.room-tile {
      width: $longSidesWidth;
      height: $longSidesHeight;
    }
    &.room-tile-mikvah {
      width: $mikvahSquareWidth;
      height: $mikvahSquareHeight;
    }
    &.room-tile.busyHallway {
      background: $main-color-red;
    }

    &.room-tile-1 {width: $longSidesWidth; right: (0 * ($longSidesWidth + $gapWidth)); top: (1 * ($longSidesHeight + $gapHeight))}
    &.room-tile-2 {width: $longSidesWidth; right: (0 * ($longSidesWidth + $gapWidth)); top: (2 * ($longSidesHeight + $gapHeight))}
    &.room-tile-3 {width: $longSidesWidth; right: (0 * ($longSidesWidth + $gapWidth)); top: (3 * ($longSidesHeight + $gapHeight))}
    &.room-tile-4 {width: $longSidesWidth; right: (0 * ($longSidesWidth + $gapWidth)); top: (4 * ($longSidesHeight + $gapHeight))}
    &.room-tile-5 {width: $longSidesWidth; right: (1 * ($longSidesWidth + $gapWidth)); top: (4 * ($longSidesHeight + $gapHeight))}
    &.room-tile-6 {width: $longSidesWidth; right: (2 * ($longSidesWidth + $gapWidth)); top: (4 * ($longSidesHeight + $gapHeight))}
    &.room-tile-7 {width: $longSidesWidth; right: (2 * ($longSidesWidth + $gapWidth)); top: (3 * ($longSidesHeight + $gapHeight))}
    &.room-tile-8 {width: $longSidesWidth; right: (2 * ($longSidesWidth + $gapWidth)); top: (2 * ($longSidesHeight + $gapHeight))}
    
    
    &.room-tile-9 {width: $longSidesWidth; right: (0 * ($longSidesWidth + $gapWidth)); top: (1 * ($longSidesHeight + $gapHeight))}
    &.room-tile-10 {width: $longSidesWidth; right: (0 * ($longSidesWidth + $gapWidth)); top: (2 * ($longSidesHeight + $gapHeight))}
    &.room-tile-11 {width: $longSidesWidth; right: (0 * ($longSidesWidth + $gapWidth)); top: (3 * ($longSidesHeight + $gapHeight))}
    &.room-tile-12 {width: $longSidesWidth; right: (0.5 * ($longSidesWidth + $gapWidth)); top: (4 * ($longSidesHeight + $gapHeight))}
    &.room-tile-13 {width: $longSidesWidth; right: (1.5 * ($longSidesWidth + $gapWidth)); top: (4 * ($longSidesHeight + $gapHeight))}
    &.room-tile-14 {width: $longSidesWidth; right: (2 * ($longSidesWidth + $gapWidth)); top: (3 * ($longSidesHeight + $gapHeight))}
    &.room-tile-15 {width: $longSidesWidth; right: (2 * ($longSidesWidth + $gapWidth)); top: (2 * ($longSidesHeight + $gapHeight))}
    &.room-tile-16 {width: $longSidesWidth; right: (2 * ($longSidesWidth + $gapWidth)); top: (1 * ($longSidesHeight + $gapHeight))}
    
    &.room-tile-17 { height: 48.7%; width: 48.4%; right: 51.5%; top: 0;}
   
    
    &.room-tile-mikvah-1 { right: (1 * ($longSidesWidth + $gapWidth)); top: (2 * ($longSidesHeight + $gapHeight)); }
    &.room-tile-mikvah-2 { right: (1 * ($longSidesWidth + $gapWidth)); top: (2 * ($longSidesHeight + $gapHeight)); }
    &.room-tile-mikvah-3 { height: 48.7%; width: 100%; left: 0%; bottom: 0%;  }
    
    
    &.hallway-1 { right: 19.5%; bottom: 2.5%; height: $gapHallwayIndicatorHeight; width: $gapHallwayIndicatorWidth;}
    &.hallway-2 { right: 19.5%; top: 2.5%; width: $gapHallwayIndicatorWidth; height: $gapHallwayIndicatorHeight;}
    &.hallway-3 { left: 16%; bottom: 2.5%; width: 84%; height: $gapHallwayIndicatorHeight;}
    &.hallway-4 { left: 16%; top:  2.5%; width: 84%; height: $gapHallwayIndicatorHeight;}
  }

  .room-tile-mikvah {
    &.room-online {
      background: rgba($main-color-blue, .2);
    }
    &:not(.clickable) {
      cursor: default;
    }
    .body {
      text-align: center;
      > div {
        height: 30px;
      }
      .status {
        display: inline-block;
        padding-inline-start: 10px; 
        padding-inline-end: 10px;
        background-color: $main-color-blue;
        &.offline{
          background-color: $status-color-grey;
        }
      }
      .room-name {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          text-transform: uppercase;
        }
        .letter {
          font-size: 33px;
          font-weight: bold;
        }
      }
      .attendant {
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      .vip-attendant {
        height: auto;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 7.5px;
      }
    }
  }

  .receptionist-tile {
    width: $longSidesWidth * 1;
    height: $longSidesHeight * .8;
    background: #fff;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    .name {
      font-weight: normal;
    }
    &.receptionist-tile-a {
      left: 28%;
      bottom: 9%;
    }
  }
}
