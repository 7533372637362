.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  .number {
    font-size: 330px;
    font-weight: 400;
    line-height: 1.4;
  }
}

.modal-timer {
  h4 {
    padding-top: 15px;
  }
  .modal-body {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .arrows {
    .btn {
      display: block;
      .icon {
        width: 80px;
        height: 80px;
      }
      margin: 10px 0;
    }
  }
  .timerButtons {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
  }
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
