//$enable-rounded: false;

$text-color: #545454;
$text-color-light: #777171;

$main-color-red: #DC3C50;
$main-color-orange: #DFA649;
$main-color-light-orange: #EBD8CA;
$main-color-faded-orange: #B9955B;
$main-color-yellow: #E4D621;
$main-color-green: #59DA8D;
$main-color-blue: #4A90E2;
$main-color-purple: #AF32C9;
$main-color-light-purple: #f8eeff;
$main-color-grey: #9A9A9A;
$main-color-lightgrey: #F5F4F2;
$main-color-navy: #6380ae;
$main-color-stoneGray: #889698;
$main-color-beige: #fff0db;
$main-color-pink: #f7f3f4;
$main-color-textGray: #868686;
$main-color-light-green: #b3d6d8e6;
$main-color-light-blue: #96bbe5;
$main-color-maroon: #D03D56;
$main-color-dark: #917B7D;
$main-color-light: #E7D7CB;
$main-color-accent: #9D608C;

$status-color-red: $main-color-red;
$status-color-orange: $main-color-orange;
$status-color-faded-orange: $main-color-faded-orange;
$status-color-yellow: $main-color-yellow;
$status-color-green: $main-color-green;
$status-color-blue: $main-color-blue;
$status-color-light-blue: $main-color-light-blue;
$status-color-purple: $main-color-purple;
$status-color-grey: $main-color-grey;
$status-color-maroon: $main-color-maroon;

$statusColors: (
  'red': $status-color-red,
  'orange': $status-color-orange,
  'faded-orange': $status-color-faded-orange,
  'yellow': $status-color-yellow,
  'green': $status-color-green,
  'purple': $status-color-purple,
  'grey': $status-color-grey,
  'blue': $status-color-blue,
  'light-blue': $status-color-light-blue,
  'maroon': $status-color-maroon,
);

$font-family-sans-serif: Avenir, Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
$font-weight-base: 600;
$body-color: $text-color;

$primary: $text-color-light;
$secondary: $main-color-light;
//$border-radius-large: 0px;

$btn-padding-y-lg: 1.06rem;
$btn-font-size-lg: 1.125rem;
//$btn-line-height-lg: 1.25;
$btn-disabled-opacity: 0.5;

$reception-header-height: 220px;