.view-kiosk {
  .header {
    .header-left {
      max-width: 41.66667% !important;
    }
    .header-right {
      max-width: 58.33333% !important;
      .clock{
        margin-bottom: 20px;
      }
    }
    .logo {
      width: 250px;
      padding: 0;
      margin: initial;
    }
  }

  .container-body {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    .title {
      text-transform: uppercase;
      font-size: 32px;
    }
    .reservation-number {
      font-size: 80px;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 15px;
      color: $text-color-light;
    }
    .reservation-summary {
      margin-bottom: 25px;
      @include list-unstyled;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        font-weight: 500;
        font-size: 40px;
      }
      .icon {
        width: 40px;
        height: 65px;
        text-align: center;
        margin-inline-end: 15px;
      }
      .price {
        margin-inline-start: unset;
        padding-inline-start: 8px;
        font-size: 80%;
        color: $text-color-light;
      }
    }    
  }
  .kiosk-pay{
    .balance {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      font-weight: 500;
      font-size: 40px;
    }
    .icon {
      width: 40px;
      height: 65px;
      text-align: center;
      margin-inline-end: 15px;
    }
    .price {
      margin-inline-start: unset;
      padding-inline-start: 8px;
      font-size: 80%;
      color: $text-color-light;
    }
  }
  .instructions {
    font-size: 36px;
    font-weight: normal;
    margin: 0 auto;
    margin-bottom: 40px;
    width: 75%;
    text-transform: uppercase;
    h1 {
      font-size: 90px;
      font-weight: 600;
    }
    h2 {
      font-size: 45px;
      font-weight: 600;
    }
  }

  .icon-arrow {
    font-size: 140px;
    color: $main-color-orange;
    display: block;
    margin: 30px auto;
  }

  .btn-row {
    > .btn:not(:last-child) {
      margin-inline-end: 40px;
    }
  }

  .wrapper-phonenumber {
    direction: ltr;
    height: 188px;
    margin-bottom: 40px;
    display: inline-block;
    flex-direction: column;
    justify-content: center;
  }

  .flippers {
    transform: scale(2.7);
    justify-content: center;
    pointer-events: none;
    display: inline-flex;
    .flipper {
      margin-top: 60px;
      margin-bottom: 60px;
      &.full {
        min-width: 30vw;
        font-size: 40px;
      }
    }
  }

}