@import './room-tile';
@import "~react-day-picker/lib/style";

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.heartBeat {
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: heartBeat;
  animation-duration: 1.3s;
  animation-timing-function: ease-in-out;
}

.container-page.view-map {
  .container-body {
    display: grid;
    height: 100%;
    .container-map {
      padding: 0 40px;
    }
  }
}
.container-page.view-receptionist-map {
  height: calc(100vh - #{$reception-header-height});
  .room-availability{
    position: absolute;
    bottom: 0;
  }
  .map-top {
    display: flex;
    text-align: center;
    font-size: 15px;
    font-weight: normal;
    text-transform: uppercase;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    bottom: 0%;
    z-index: 2;
    .title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    .main-entrance {
      color: #9a9a9a;
    }
    .waiting-cubicles {
      margin-bottom: 15px;
      margin-inline-end: 60px;
      min-width: 320px;
      .cubicles {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 6px;
        font-size: 12px;
        .cubicle {
          background: $main-color-lightgrey;
          height: 72px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          .number {
            font-size: 24px;
            font-weight: bold;
          }
          &.status-assigned,
          &.status-occupied {
              color: #fff;
            background: $text-color-light;
          }
          &.status-offline {
            background: rgba(black, .1);
          }
          &.status-needscleaning {
            background: rgba($main-color-purple, .2);
          }
          &:nth-child(-n+5) { grid-row: 1; }
          &:nth-child(1) { grid-column: 5; }
          &:nth-child(2) { grid-column: 4; }
          &:nth-child(3) { grid-column: 3; }
          &:nth-child(4) { grid-column: 2; }
          &:nth-child(5) { grid-column: 1; }
        }

      }
    }
    .waiting-room {
      min-width: 355px;
      .slots {
        background: $main-color-lightgrey;
        padding: 25px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 6px;
        .slot {
          background: #fff;
          //width: 36px;
          height: 50px;
          &.active {
            background: #777171;
          }
        }

      }
    }
  }
  .receptionist {
    min-width: 355px;
    position: absolute;
    right: 0%;
    bottom: 0%;
    text-transform: uppercase;
    z-index: 2;
    .title{
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 15px;
      text-align: center;
    }
    .selection{
      padding: 17px 20px;
      background: $main-color-lightgrey;
      font-size: 12px;
    }
    .dropdown{
      margin-top: 10px;
    }
  }
  .container-body {
    display: grid;
    height: 100%;
    .container-map {
      display: grid;
      grid-template-rows: 1fr auto;
    }
  }
  .container-sidebar {
    padding: 40px;
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .sidebar-top {
      flex-grow: 1;
    }
    .sidebar-bottom {
      .table {
        @extend .table-sm;
        th {
          padding-top: $table-cell-padding;
        }
      }
    }

    h3 {
      text-transform: uppercase;
      font-size: 18px;
      color: $text-color-light;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }
}

.reception-background{
  background-color: rgba($main-color-light-orange, .2) !important;
  height: calc(100vh - #{$reception-header-height});
  &.background-setup{
    min-height: calc(100vh - #{$reception-header-height});
    height: unset;
  }
}
.reception-view{
  background-color: #FFF;
  padding: 25px 45px;
  height: 100%;
  overflow-y: auto;
  .res-table{
    border-top: none;
    thead{
      th{
        position: sticky;
        cursor: pointer;
        background-color: #FFF;
        top: 81px;
        z-index: 1;
        @media (max-width: 1053px) {
          top: 136px
        }
      }
    }
    td{
      border-top: 1px solid #dee2e6;
    }
  }
}
.view-user{
  .reception-view{
    .res-table{
      thead{
        th{
          position: static;
          top: 0px;
        }
      }
    }
  }
}
.icon-toggle{
  svg{
    height: 26px;
    path{
      fill: $text-color; 
    }
  }
}
.fixed-head{
  position: sticky;
  top: 0;
  background-color: #FFF;
  z-index: 2;
  padding-top: 25px;
  &:after{
    position: absolute;
    content: '';
    border-bottom: 2px solid $main-color-grey;
    left: 0;
    bottom: 3px;
    width: 100%;  
  }
}
.reception-view::-webkit-scrollbar-thumb {
  background: #ead8ca;
}
.reception-view::-webkit-scrollbar-track {
    background: #e5e5e5;
}
.reception-view::-webkit-scrollbar {
    width: 15px;
}

.reception-tabs{
  display: flex;
  flex-wrap: wrap;
  padding-inline-start: 0;
  margin-bottom: 0;
  list-style: none;
  .nav-item{
    font-size: 22px;
    padding: 15px;
  }
  .active{
    background-color: #FFF;
    font-weight: 600;
  }
}

.container-page.view-dashboard {

  .reception-view{
    display: flex;
    height: 94%;
    .reservation-list{
      overflow-y: auto;
      height: 100%;
      padding-inline-end: 20px;
    }
    .reservation-list::-webkit-scrollbar-thumb {
      background: #ead8ca;
    }
    .reservation-list::-webkit-scrollbar-track {
        background: #e5e5e5;
    }
    .reservation-list::-webkit-scrollbar {
        width: 15px;
    }
  }    
  .receptionist-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 900px;
    grid-template-columns: 1fr;
    width: 400px;
    grid-gap: 40px;
    flex-grow: 1;
    align-content: center;
    text-align: center;
    .button {
      border: 1px #d8d2d2 solid;
      background-color: white;
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      box-shadow: 0 3px 5px 0 rgba(0,0,0,0.15);
      text-transform: uppercase;
      transition: background-color .3s ease;
      text-decoration: none;
      &:hover {
        background-color: #eee
      }
      .title {
        letter-spacing: 1px;
        font-weight: bold;
        font-size: 45px;
        color: $text-color-light;
      }
      .subtitle {
        letter-spacing: 0.8px;
        font-size: 18px;
        color: $text-color-light;
      }
    }
  }

  .receptionist-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    flex-grow: 1;
    align-content: center;
    text-align: center;
    &.checkin-card{
      .btn{
        width: 100%;
        min-width: unset;
        max-width: 220px;
      }
    }
    .btn{
      border-radius: 0;
      background-color: $main-color-light-orange;
    }
    .card {
      border: 1px #d8d2d2 solid;
      background-color: white;
      height: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      text-transform: uppercase;
      transition: background-color .3s ease;
      text-decoration: none;
      .title {
        letter-spacing: 1px;
        font-weight: bold;
        font-size: 35px;
        line-height: 40px;
        color: $text-color-light;
      }
      .subtitle {
        letter-spacing: 0.8px;
        font-size: 18px;
        color: $text-color-light;
      }
    }
  }

  .reservation-count {
    text-transform: uppercase;
    padding-bottom: 40px;
    .title {
      font-weight: 600;
      letter-spacing: 1px;
      text-align: center;
      font-size: 21px;
      color: $text-color-light;
      padding-bottom: 10px;
      border-bottom: 2px solid #d8d2d2;
      align-items: flex-end;
      .num{
        background-color: $main-color-light-orange;
        padding: 7px 20px;
        border-radius: 16.5px;
        font-size: 18px;
      }
    }
    .row {
      letter-spacing: 0.4px;
      font-size: 18px;
    }
    .progress-reservations {
      height: 30px;
      margin: 15px auto;
    }
    .overview-list{
      padding: 15px 0px;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 1.3px;
      text-transform: none;
      .num{
        font-weight: 600;
      }
    }
  }
}

.room-availability {
  h3 {
    text-align: start;
    border-bottom: 2px solid #9A9A9A;
    padding-bottom: 8px;
  }
  .items {
    display: flex;
    margin: 0 auto;
    margin-top: 0.75rem;
    justify-content: center;
    text-align: center;
    > div {
      flex: 1 1 33%;
      margin-bottom: 25px;
      padding: 0px 7.5px;
    }
  }
  .title {
    font-size: 18px;
    color: $text-color-light;
    white-space: nowrap;
  }
  .status {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    width: 36px;
    display: inline-block;
    border-radius: 10px;
    text-align: center;
    &.status-inactive {
      //opacity: .2;
      background: #fff;
      color: transparent;
      box-shadow: 0 0 1px rgba(0,0,0,.4);
    }
  }
}



.view-receptionist-checkin {
  .container-body {
    display: flex;
    flex-direction: column;
    font-size: 18px;
  }
  .reservation-kallah{
    .modal-body{
      min-width: 500px;
      .zman-tevilah{
        font-size: 18px;
      }
      .closing-time{
        font-size: 18px;
      }
    }
    .reactJewishDatePicker .selectedDate{
      margin-inline-start: 10px;
    }
    .preassign-room{
      .dropdown-menu{
        max-height: 500px;
        overflow: auto;
      }
    }
  }
}
.container-checkin {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  //border: 1px solid #979797;
  border-radius: 8px;
  .checkin-message {
    padding: 20px 40px;
  }

  .modal-header {
    padding: 20px 32px;
    .header-title {
      text-align: center;
      text-transform: uppercase;
      margin-inline-start: auto;
      margin-inline-end: auto;
      h4 {
        font-weight: bold;
        font-size: 45px;
        letter-spacing: 1px;
        margin-bottom: 0;
        color: $text-color-light;
      }
      p {
        text-align: center;
        font-size: 18px;
        letter-spacing: 1px;
        margin-bottom: 0;
        color: $text-color;
      }
    }
    svg {
      height: 42px;
      width: 42px;
      color: $text-color-light;
    }
    span {
      cursor: pointer;
    }
  }
  .modal-body {
    flex-grow: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    // width: 1000px;
    margin: 0 auto;
    .zman-tevilah{
      font-size: 20px;
    }
    .closing-time{
      font-size: 20px;
    }
  }
  .modal-footer {
    text-align: center;
    padding: 40px;
    padding-top: 0;
  }
  .checkin-numbers {
    background-color: #F5F4F2;
    text-align: center;
    padding: 40px;
    margin-bottom: 40px;
    direction: ltr;
    .title {
      font-size: 18px;
      text-transform: uppercase;
    }
    .form-control {
      font-size: 88px;
      line-height: 1;
      text-align: center;
      border: none;
      background: none;
      font-weight: normal;
      height: auto;
      &:focus {
        box-shadow: none;
        outline: none;
      }
      &::placeholder {
        color: #424242;
        opacity: 0.25;
      }
    }
    .react-tel-input {
      .form-control {
        text-align: start;
      }
    }
    .dropdown {
      .dropdown-menu {
        width: 100%;
      }
    }
    input::-webkit-inner-spin-button {
      display: none;
      margin: 0;
    }
  }
  &.checkin-payment {
    .modal-body-inner {
      display: grid;
      grid-template-columns: 1fr 2fr;
      text-align: center;
    }
    .payment-summary {
      list-style-type: none;
      padding-inline-start: 0px;
      margin-top: 45px;
      margin-bottom: 85px;
      li {
        margin-bottom: 14px;
      }
    }
  }
  .checkin-reservation {
    border-inline-end: 2px $main-color-light-orange dashed;
    padding-inline-end: 30px;
    margin-inline-end: 30px;
    text-align: start;
    .title {
      text-transform: uppercase;
    }
    .reservation-number {
      font-size: 60px;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 15px;
      color: $text-color-light;
    }
    .reservation-summary {
      @include list-unstyled;
      li {
        display: flex;
        align-items: center;
        padding: 10px 0;
        font-weight: 500;
      }
      .icon {
        width: 24px;
        height: 24px;
        text-align: center;
        margin-inline-end: 15px;
      }
    }
    .saveNote {
      display: block;
    }
    .btn-link {
      color: $main-color-blue;
      font-size: 14px;
    }
  }
  .checkin-payment {
    .balance-due {
      .icon {
        width: 60px;
        height: 60px;
        margin: 0 auto;
      }
    }
    .btn-lg{
      width: 100%;
      min-width: unset;
    }
    .table {
      td {
        font-size: inherit;
      }
    }

    .title {
      letter-spacing: 0.8px;
    }

    /*.form-group {
      background: white;
      margin-bottom: 1rem;
      width: 336px;
      height: 60px;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }
    .form-desc {
      padding-inline-start: 18px;
      text-align: start;
      width: 101px;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 12px;
      display: inline-block;
      opacity: 0.6;
      margin-bottom: 0px;
      line-height: 16px;
      letter-spacing: 0.3px;
    }
    .form-control {
      display: inline-block;
      border: 1px solid white;
      font-size: 36px;
      width: 60%;
      padding: 0px;
    }
    .form-control:focus {
      border-color: white;
      outline: 0;
      box-shadow: none;
    }*/
    .money-amount {
      font-size: 36px;
    }
    .btn-link {
      font-size: 14px;
      color: $main-color-blue;
    }
    .saved-cards {
      .header {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba($text-color, .1);
        margin-bottom: 20px;
        .title {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: normal;
        }
        .btn {
          margin-inline-start: auto;
        }
      }
    }
    .new-card {
      .form-group {
        width: auto;
      }
    }
    .btn-cancel {
      color: $text-color;
    }
  }
  .saved-cards-buttons{
    max-width: 500px;
    margin: 0 auto;
    .input-group{
      width: unset;
      input{
        height: 100%;
      }
    }
    .saved-cards-buttons {
      margin-bottom: 30px;
    }
  }
  .btn-saved-card {
    .btn-inner {
      display: flex;
      align-items: center;
    }
    .svg-inline--fa {
      font-size: 25px;
      margin-inline-end: 10px;
    }
    .last4 {
      font-weight: bold;
    }
    .amount {
      margin-inline-start: auto;
    }
  }
  .payment-view {
    background-color: $main-color-lightgrey;
    padding: 30px;
    .btn-link-blue{
      color: $main-color-blue;
      font-size: 14px;
    }
    .form-row{
      width: 415px;
      margin-inline-start: auto;
      margin-inline-end: auto;
      label{
        margin-bottom: 0px;
      }
      .form-group {
        margin: 0 auto 20px;
        position: relative;
        label {
          position: absolute;
          text-align: start;
          // width: 100px;
          font-weight: 400;
          text-transform: uppercase;
          font-size: 12px;
          opacity: 0.6;
          top: 50%;
          left: 0;
          margin-inline-start: 10px;
          transform: translateY(-50%);
        }
        .form-control {
          padding-inline-start: 195px;
          &.chid{
            padding-inline-start: 160px;
          }
        }
      }
    }
    .new-card {
      .form-control {
        padding-inline-start: 15px;
      }
    }
    .btn-terminal-start {
      margin-bottom: 30px;
      .icon {
        display: inline-block;
        margin-inline-end: 30px;
      }
    }
  }
  .checkin-roomType {
    text-align: center;
    &:not(:last-child) {
      margin-bottom: 40px;
    }
    .btn {
      margin: 0 10px 10px;
      font-size: 18px;
    }
    .btn-text {
      font-weight: normal;
      text-transform: uppercase;
      font-size: 18px
    }
    .price {
      font-weight: normal;
      font-size: 14px;
      color: $text-muted;
    }
    .icon {
      width: 55px;
      height: 55px;
      svg {
        font-size: 30px;
      }
    }
    .title {
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    form {
      font-size: 20px;
    }
    input[type=checkbox] {
      margin-inline-end: 5px;
      transform: scale(1.7);
    }
  }
}
.lang-he{
  .payment-view {
    .form-group {
      label {
        position: absolute;
        text-align: start;
        width: 110px;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 12px;
        opacity: 0.6;
        top: 50%;
        left: unset;
        right: 0;
        margin-inline-start: 10px;
        transform: translateY(-50%);
      }
    }
  }
}
.reservation-summary {
  @include list-unstyled;
  li {
    display: flex;
    align-items: center;
    padding: 10px 0;
    font-weight: 500;
  }
  .icon {
    width: 24px;
    height: 24px;
    text-align: center;
    margin-inline-end: 15px;
  }
  .price {
    margin-inline-start: auto;
    padding-inline-start: 8px;
    font-size: 80%;
    color: $text-color-light;
  }
}


.container-page.view-attendant-map {
  display: grid;
  header.header {
    .clock {
      font-size: 80px;
      margin-bottom: 10px;
    }
  }
}

.modal-room-details, .modal-reservation-details {
  .modal-header {
    padding: 25px 40px;
    align-items: flex-end;
    > div {
      margin-inline-end: 40px;
    }
    .title {
      color: $text-color-light;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .room-status {
      margin-inline-start: auto;
      .flipper {
        padding-inline-start: 30px;
        padding-inline-end: 30px;
        min-width: 300px;
      }
    }
    .close {
      align-self: flex-start;
      margin-inline-start: 0;
    }
  }
  .modal-body {
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 0;
    min-height: 500px;
    > div {
      padding: 40px;
    }
    .col-side {
      border-left: 1px solid $modal-header-border-color;
      .buttons {
        display: grid;
        @include media-breakpoint-up(lg) {
          grid-template-columns: repeat(2, 160px);
        }
        grid-gap: 30px;
        justify-content: space-evenly;
        text-align: center;
        .btn {
          margin: 0 auto 10px;
        }
        .title {
          color: $text-color-light;
          font-weight: 500;
        }
      }

    } 
  }
}

.modal-reservation-details {
  .modal-body {
    grid-template-columns: 1fr auto;
  }
  .res-id{
    font-size: 10px;
    position: absolute;
    bottom: 5px;
    left: 15px;
  }
}

.user-note{
  white-space: pre-line;
}

.modal-room-screen {
  .modal-body {
    padding: 0;
    padding-bottom: 62.5%;
    iframe {
      border: 0;
      position: absolute;
      width: 124.99%;
      height: 124.99%;
      transform: scale(.8);
      transform-origin: 0 0;
    }
  }
}

.table {
  @extend .table-borderless;
  border-top: 2px solid $main-color-grey;
  th, td {
    &:first-child {
      padding-inline-start: 0;
    }
    &:last-child {
      padding-inline-end: 0;
    }
  }
  th {
    color: $text-color-light;
    font-weight: bold;
    font-size: 12px;
  }
  td {
    font-size: 14px;
    font-weight: normal;
    &.room-number {
      font-weight: bold;
    }
    .icon-excluded{
      height: 16px;
      display: inline;
      div{
        display: inline;
      }
      svg{
        height: 16px;
        display: inline;
        .cls-1{
          fill: $text-color;
          stroke: $text-color;
        }
      }
    }
  }
  .icon-merch{
    height: 16px;
    display: inline;
    div{
      display: inline;
    }
    svg{
      height: 16px;
      display: inline;
    }
  }
  .status-name {
    text-transform: capitalize;
  }
  tr.clickable {
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
      background: fade-out($color: $main-color-light-orange, $amount: .7);
    }
  }
}

.reception-header {
  .weather{
    padding-inline-start: 10%;
  }
  .header-left{
    .logo{
      width: 250px;
      margin-inline-end: 8%;
      margin-inline-start: unset;
    }
  }
  .clock{
    margin-bottom: 20px;
  }
  //.full-date {
  //  font-size: 27px;
  //}
  // .header-right {
  //   text-align: start !important;
  //   padding-inline-start: 90px;
  // }
  // .weather {
  //   text-align: start !important;
  // }
}

.choose-printer{ 
  .btn-circle{
    height: 150px;
    width: 150px;
  }
  .btn-circle.active{
    color: #FFF;
  }
}

.reservation-setup {
  .title {
    text-transform: uppercase;
    svg {
      height: 18px;
      width: 18px;
      margin-inline-end: 20px;
    }
  }
}

.newReservation-body {
  // overflow-y: auto;
  display: grid;
  grid-template-columns: 150px 1fr 1fr;
  margin-bottom: 40px;
  .reservation-sidebar {
    display: grid;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .btn-container {
      font-size: 12px;
    }
    .btn {
      margin: 5px auto;
    }
    .btn-circle {
      height: 70px;
      width: 70px;
      border: none;
      box-shadow: none;
      display: block;
      .icon {
        width: 40px;
        height: 40px;
      }
    }
  }
  .times-container {
    text-align: center;
    border-left: 1px solid $main-color-lightgrey;
    padding: 0 40px;
    .times {
      padding-top: 20px;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(4, 1fr);
      .slot {
        font-size: 14px;
        font-weight: normal;
        padding: 19px 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $main-color-lightgrey;
        //margin-bottom: 0;
        border: 2px solid transparent;
        cursor: pointer;
        position: relative;
        &.selected {
          font-weight: bold;
        }
        &:hover, &.selected {
          background-color: $text-color-light; 
          color: $main-color-lightgrey;
          &.disabled {
            color: $main-color-lightgrey;
          }
        }
        &.disabled {
          color: rgba($text-color, .3);
          &.selected {
            background: $main-color-lightgrey;
            border: 2px dashed $main-color-red;
            color: rgba($text-color, .3);
          }
          &:before {
            content: '';
            position: absolute;
            top: 0; bottom: 0; left: 0; right: 0;
            margin: -2px;
            background: linear-gradient(to top left,
              rgba($main-color-red,0) 0%,
              rgba($main-color-red,0) calc(50% - 0.8px),
              rgba($main-color-red,.3) 50%,
              rgba($main-color-red,0) calc(50% + 0.8px),
              rgba($main-color-red,0) 100%),
          }
        }
        &.custom{
          grid-column: span 2;
        }
        .customTime{
          font-size: 14px;
          padding: 3px 4px;
        }
      }
    }
  }
}
.reservation-footer {
  text-align: center;
  .btn {
    width: 250px;
    height: 60px;
  }
}

.form-control-datepicker {
  width: 120px;
}

.smsResultTable{
  tbody{
    td{
      font-size: 14px;
      padding: 0.25rem;
    }
  }
}

.lang-he{
  .DayPickerInput-OverlayWrapper{
    right: 60px !important;
    left: unset !important;
  }
  .DayPicker-Day {
    &.DayPicker-Day--selected {
      border-radius: 0;
      &.DayPicker-Day--start {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
      &.DayPicker-Day--end {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
    }
  }
}
.lang-en{
  .DayPicker-Day {
    &.DayPicker-Day--selected {
      border-radius: 0;
      &.DayPicker-Day--start {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
      &.DayPicker-Day--end {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }
  }
}
.reactJewishDatePicker{
  display: inline-block !important;
  margin-bottom: 0px !important;
  font-size: 16px;
}
.reactJewishDatePicker .navigation{
  height: auto !important;
}
.reactJewishDatePicker .selectedDate{
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem !important;
  display: flex;
  align-items: center;
  padding: 2px 25px 2px 5px !important;
  border: 1px solid #ced4da !important;
}
.reactJewishDatePicker .calendarIcon{
  top: 10px !important;
}
.reactJewishDatePicker .weekday{
  color: $text-color-light !important;
}
.reactJewishDatePicker .weekdayWrapper{
  background-color: $main-color-light-orange !important;
}

.container-page {
  .react-calendar {
    //box-shadow: 0 10px 50px 0 rgba($text-color, 0.2), 0 30px 40px 0 rgba($text-color, 0.2);
    border-radius: 4px;
    border: none;
    font-family: inherit;
    width: 80%;

    abbr[title] {
      text-decoration: none;
    }
    button {
      border-radius: 4px;
    }
    .react-calendar__navigation__label {
      font-weight: 600;
    }
    .react-calendar__navigation button[disabled] {
      background: none;
      &:not(.react-calendar__navigation__label) {
        opacity: 0;
      }
    }
    .react-calendar__tile {
      padding-top: 1.5em;
      padding-bottom: 1.5em;
    }
    .react-calendar__tile--now {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        background: $main-color-blue;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .react-calendar__month-view__days__day {
      color: $text-color;
      font-weight: 600;
      font-size: 18px;
      &:disabled {
        opacity: .5;
        &:not(.react-calendar__tile--active) {
          background: none;
        }
      }
    }
    .react-calendar__month-view__weekdays__weekday {
      font-weight: normal;
    }
    .react-calendar__month-view__days__day--neighboringMonth {
      opacity: .3;
      font-weight: normal;
    }
    .react-calendar__tile--active,
    .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
      background-color: $text-color-light;
      color: #fff;
    }
  }
}
