@import 'assets/scss/variables';

@font-face {
  font-family: 'Avenir';
  src: url('assets/fonts/AvenirNextLTPro-Regular.otf')  format('truetype'),
}
@font-face {
  font-family: 'Avenir';
  src: url('assets/fonts/AvenirNextLTPro-UltLt.otf')  format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Avenir';
  src: url('assets/fonts/AvenirNextLTPro-Demi.otf')  format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Avenir';
  src: url('assets/fonts/AvenirNextLTPro-Bold.otf')  format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Rubik';
  src: url('assets/fonts/Rubik-Regular.ttf')  format('truetype');
}
@font-face {
  font-family: 'Weather';
  src: url('assets/fonts/WeatherTime.ttf')  format('truetype');
}

.text-red { color:  $main-color-red; }
.text-orange { color:  $main-color-orange; }
.text-faded-orange { color:  $main-color-faded-orange; }
.text-yellow { color:  $main-color-yellow; }
.text-green { color:  $main-color-green; }
.text-blue { color:  $main-color-blue; }
.text-light-blue { color:  $main-color-light-blue; }
.text-purple { color:  $main-color-purple; }
.text-grey { color:  $main-color-grey; }
.text-maroon { color:  $main-color-maroon; }

.bg-red { background:  $main-color-red !important; }
.bg-orange { background:  $main-color-orange !important; }
.bg-yellow { background:  $main-color-yellow !important; }
.bg-maroon { background:  $main-color-maroon !important; }
.bg-green { background:  $main-color-green !important; }
.bg-blue { background:  $main-color-blue !important; }
.bg-purple { background:  $main-color-purple !important; }
.bg-grey { background:  $main-color-grey !important; }

/*
Reserved
Arrived
Wait Room
Assigned
Prep
Waiting for Tevilah
Next for Tevilah
In Mivkah
Dressing
Done
Needs Cleaning
Cleaning
Available
Mainenance
*/

@import '~bootstrap/scss/bootstrap-reboot';
//@import '~bootstrap/scss/functions';
//@import '~bootstrap/scss/variables';
//@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/_grid';
@import '~bootstrap/scss/_utilities';
@import '~bootstrap/scss/_transitions';
@import '~bootstrap/scss/_modal';
@import '~bootstrap/scss/_close';
@import '~bootstrap/scss/_forms';
@import '~bootstrap/scss/_buttons';
@import '~bootstrap/scss/_alert';
@import '~bootstrap/scss/_tables';
@import '~bootstrap/scss/_progress';
@import '~bootstrap/scss/_list-group';
@import '~bootstrap/scss/_type';
@import '~bootstrap/scss/_dropdown';
@import '~bootstrap/scss/_card';
@import '~bootstrap/scss/_tooltip';
@import '~bootstrap/scss/_input-group';

@import 'assets/scss/buttons';
@import 'assets/scss/flipper';
@import 'assets/scss/modal';
@import 'assets/scss/timer';
@import 'assets/scss/form-check';

@import 'assets/scss/view-receptionist';
@import 'assets/scss/view-attendant';
@import 'assets/scss/view-kiosk';
@import 'assets/scss/view-waitingroom';
@import 'assets/scss/view-manager';
@import 'assets/scss/screen-saver';
@import 'assets/scss/special-mode';

body {
  overscroll-behavior: contain;
}

img{
  max-width: 100%;
}

.lang-he {
  direction: rtl;
}

.cursor-pointer{
  cursor: pointer;
}
.hover-underline:hover{
  text-decoration: underline;
}

#translations-table{
  thead{
    th{
      position: sticky;
      top: 0;
      background-color: #FFF;
    }
  }
}

.dir-ltr{
  direction: ltr;
}
.dir-rtl{
  direction: rtl;
}

.mis-auto{
  margin-inline-start: auto !important;
}
.mis-0{
  margin-inline-start: 0 !important;
}
.mis-1{
  margin-inline-start: 0.25rem !important;
}
.mis-2{
  margin-inline-start: 0.5rem !important;
}
.mis-3{
  margin-inline-start: 1rem !important;
}
.mis-4{
  margin-inline-start: 1.5rem !important;
}
.mis-5{
  margin-inline-start: 2rem !important;
}
.mie-auto{
  margin-inline-end: auto !important;
}
.mie-0{
  margin-inline-end: 0 !important;
}
.mie-1{
  margin-inline-end: 0.25rem !important;
}
.mie-2{
  margin-inline-end: 0.5rem !important;
}
.mie-3{
  margin-inline-end: 1rem !important;
}
.mie-4{
  margin-inline-end: 1.5rem !important;
}
.mie-5{
  margin-inline-end: 2rem !important;
}
.pis-0{
  padding-inline-start: 0 !important;
}
.pie-2{
  padding-inline-end: 0.5rem !important;
}
.pie-4{
  padding-inline-end: 1.5rem !important;
}
.text-start{
  text-align: start !important;
}
.text-end{
  text-align: end !important;
}
.dropdown-toggle::after{
  margin-inline-start: 0.255em;
}
.swiper-pagination-bullet-active{
  background: $text-color-light !important;
}
.icon-sort{
  display: inline;
  div{
    display: inline;
  }
  svg{
    height: 16px;
    display: inline;
    margin-inline-start: 5px;
    .cls-1{
      fill: $text-color;
      stroke: $text-color;
    }
  }
}
.sort{
  text-decoration: underline;
}
.canSort:hover{
  text-decoration: underline;
}
.icon-walkin, .icon-private{
  height: 20px;
  display: inline;
  div{
    display: inline;
  }
  svg{
    height: 20px;
    display: inline;
    .cls-1, .cls-3{
      fill: $text-color;
      stroke: $text-color;
    }
  }
}

.container-page {
  user-select: none;
  // background: url(assets/images/bg-back-marble.jpg) no-repeat center;
  background-size: cover;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  &.container-page-widescreen {
    @media (min-width: 2601px) {
      flex-direction: row;
      > div {
        .container-page {
          width: auto;
        }
      }
      > .container-page {
        width: 35%;
        height: calc(100vh - #{$reception-header-height});
      }
      > .view-map-extension {
        width: 65%;
        height: calc(100vh - #{$reception-header-height});
      }
      header.header {
        &::after {
          border-bottom-right-radius: 15px;
        }
      }
      .receptionist-menu {
        .menu-map {
          display: none;
        }
      }
    }
    @media (max-width: 2600px) {
      .view-map-extension {
        display: none;
      }
    }
  }
  
  &.view-room {
    user-select: none; 
    //width: 100vw;
    //height: 100vh;
    //display: grid;
    //grid-template-rows: auto 1fr;
  }

  &.has-sidebar {
    display: grid;
    grid-template-columns: minmax(300px,400px) 1fr;
    .container-sidebar {
      background-color: $text-color-light;
      color: #f7f7f7;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      .sidebar-header {
        padding: 40px;
        background: linear-gradient(to bottom, #545454 60%, rgba(#545454, 0%));
        .logo {
          width: 80%;
          svg {
            fill: $gray-100;
            width: 100%;
          }
        }
        .receptionist-select {
          margin-top: 50px;
          .reception {
            color: #EBD8CA;
            text-transform: uppercase;
          }
          .attendant-name {
            text-transform: capitalize;
            color: white;
            font-size: 20px;
            padding-top: 13px;
            cursor: pointer;
            display: inline-block;
          }
        }
      }
      .sidebar-body {
        overflow-y: auto;
        ul {
          @include list-unstyled();
          padding: 20px;
          li {
            padding-bottom: 8px;
          }
        }
        a {
          svg {
            width: 32px;
            height: 32px;
            margin-inline-end: 25px;
            text-align: center;
          }
          opacity: 0.7;
          display: flex;
          font-size: 22px;
          font-weight: normal;
          padding: 20px;
          color: #fff;
          text-decoration: none;
          cursor: pointer;
          @include hover {
            color: #fff;
            opacity: 1;
          }
          &.active {
            font-weight: 600;
            opacity: 1;
          }
        }
        .btn-link {
          color: #fff;
          font-weight: normal;
          opacity: 0.7;
          @include hover {
            opacity: 1;
          }          
        }
      }
    }
  }
}


header.header {
  display: grid;
  grid-row-gap: 25px;
  padding: 20px 50px;
  // background: url(assets/images/bg-back-pink.jpg) no-repeat center;
  background-color: $main-color-light-orange;
  background-size: cover;
  position: relative;
  margin-bottom: 20px;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    width: 100%;
    background: $text-color-light;
    height: 20px;
    left: 0px;
  }
  > div {
    @extend .row;
    > div {
      @extend .col;
    }
  }
  .header-left {
    
  }
  .header-center {
    text-align: center;
  }
  .header-right {
    text-align: end;
    .lang-left {
      position: fixed;
      top: 90%;
      right: 6%;
    }
    .lang-right {
      position: fixed;
      top: 90%;
      right: 5%;
    }
    .lang-right:focus {
      box-shadow: none;
    }

  }
  .title {
    text-align: center;
    text-transform: uppercase;
    color: $text-color-light;
    margin-bottom: 10px;
  }

  .room-attendant, .room-arrived, .zman-tevilah, .closing-time {
    .title {
      display: inline-block;
      margin-inline-end: 10px;
      margin-bottom: 0;
    }
  }

  .room-number, .room-reservation, .room-status {
    display: inline-block;
  }

  .room-number {
    margin-inline-end: 45px;
  }

  .room-status {
    width: 300px;
    text-align: center;
  }

  .full-date {
    font-size: 26px;
    color: $text-color-light;
    line-height: 1;
    margin-top: -2px;
  }

  .clock {
    font-size: 120px;
    font-weight: normal;
    letter-spacing: -9px;
    color: $text-color-light;
    line-height: .8;
    //margin-bottom: 15px;
    .small {
      font-size: 24px;
      font-weight: 600;
      display: inline-block;
      vertical-align: top;
      padding-top: 5px;
      padding-inline-start: 10px;
      letter-spacing: 0;
    }
    .time {
    }
  }
  .header-center .clock .small {
    position: absolute;
  }

  .zman {
    text-transform: uppercase;
    font-weight: normal;
    span {
      font-weight: 600;
      font-size: 18px;
    }
  }
  
}

.zman-tevilah{
  font-size: 24px;
  margin-inline-end: -10px;
  .title {
    display: inline-block;
    margin-inline-end: 10px;
    margin-bottom: 0;
  }
  .zmanTime{
    margin-inline-end: 10px;
    display: inline-block;
  }
  .zmanTitle{
    margin-inline-end: 5px;
    display: inline-block;
    font-weight: 400;
  }
}

.closing-time{
  font-size: 24px;
  .title {
    display: inline-block;
    margin-inline-end: 10px;
    margin-bottom: 0;
  }
}

.container-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.container-body {
  padding: 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.dropdown-language {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 45px;
  z-index: 1000;
}
.lang-he{
  .dropdown-language {
    left: 0;
    right: unset;
  }  
}

.view-exit {
  .container-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.view-lobby{
  background-color: rgba($main-color-light-orange, .2);
  .header{
    .header-center{
      flex-basis: 20%;
      .clock{
        font-size: 12vh;
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }
    .zman-tevilah{
      font-size: 2.4vh;
    }
    h1{
      font-size: 5vh;
    }
    &:after{
      height: 2vh;
    }
  }
  h1{
    font-size: 4.2vh;
  }
  .logo{
    max-width: 100%;
    padding: 0px 65px;
  }
  .date{
    display: block;
    font-weight: 600;
    font-size: 7vh;
  }
  .prevdate{
    display: block;
    font-weight: 600;
    font-size: 7vh;
  }
}

.view-cubicle {
  .container-body {
    text-align: center;
    align-content: center;
    position: relative;
    .dropdown-language {
      margin: 40px;
    }
    .logo{
      max-width: 20%;
    }
    .room-reservation {
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      .title {
        margin-bottom: 60px;
      }
      .room-number {
        margin-inline-start: 20%;
      }
      .flippers {
        transform: scale(2.5);
        &.active {
          .flipper {
            background-color: $main-color-green;
          }
        }
      }
    }
  }
}

.view-room {
  header.header {
    padding-bottom: 20px;
    .header-zman{
      flex-basis: 15%;
      .zman-tevilah{
        font-size: 20px;
      }
    }
  }
  .container-body {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    position: relative;
    .dropdown-language {
      margin: 40px;  
    }
    .body-left {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
    .body-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      .title {
        font-size: 66px;
        font-weight: 600;
        margin-bottom: 15px;
        color: $text-color-light;
      }
      .instructions {
        font-size: 24px;
        font-weight: normal;
        max-width: 500px;
        margin-bottom: 15px;
      }
      .text-next {
        font-weight: 600;
        font-size: 40px;
        line-height: 1.4;
        text-transform: uppercase;
        width: 75%;
        margin-inline-start: auto;
        margin-inline-end: auto;
        color: $main-color-orange;
      }
      .lock-instructions {
        font-size: 45px;
        max-width: 500px;
        margin-bottom: 10px;
        color: $text-color-light;
      }
      .confirm-instructions {
        font-size: 36px;
        max-width: 500px;
        margin-bottom: 10px;
        color: $text-color-light;
      }
      .icon svg{
        height: 60px;
        width: 60px;
        stroke: $text-color-light;
      }
      .btn-assigned {
        height: 165px;
        width: 500px;
        background: white;
        color: $text-color-light;
        font-weight: 600;
        font-size: 26px;
        box-shadow: 0px 1px;
        border-color: #cecece
      }
      .room-queue {
        margin-bottom: 20px;
        .title {
          font-size: 18px;
          text-transform: uppercase;
          color: $main-color-grey;
        }
        .number {
          font-size: 120px;
          line-height: .8;
        }
      }
      &.attendantInRoom{
        padding: 20px;
        margin-inline-start: auto;
        width: 100%;
        grid-column: span 2;
        .btn-circle{
          width: 200px;
          height: 200px;
        }    
        .instructions{
          max-width: unset;
        }
      }
    }
  }
}

.modal-bigclock.modal-full{
  width: calc(100% - (2 * 3.75rem));
  min-height: calc(100% - (2 * 3.75rem));
  margin: 3.75rem auto;
  .modal-body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .clock{
    font-size: 330px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
  }
  .timer-clock{
    font-size: 75px;
    display: flex;
  }
  .icon{
    div{
      line-height: 1;
    }
    width: 95px;
    height: 95px;
    margin-inline-end: 17px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    .cls-1{
      stroke: #EBD8CA;
    }
  }
  .small{
    font-size: 30%;
  }
  .blink,.reg{
    line-height: 1;
  }
  time{
    vertical-align: middle;
  }
}

.modal {
  user-select: none;
  .modal-content {
    border-radius: 8px;
  }
}

.modal-done {
  [data-icon="parking"] {
    width: 32px;
    height: 32px;
    vertical-align: bottom;
  }
}

.room-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  &.status-dressing{
    grid-template-columns: none;
  }
  .btn-circle {
    width: 220px;
    height: 220px;
    // font-size: 20px;
    // line-height: 26px;
    position: relative;
    .icon {
      margin-bottom: 15px;
      width: 64px;
      height: 64px;
    }
    .badge {
      position: absolute;
      top: 5px;
      right: -15px;
      font-size: 30px;
      color: $main-color-light-orange;
      background: $text-color-light;
      border-radius: 50%;
      width: 65px;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.room-admin, .kiosk-admin, .mikvah-admin {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  min-width: 150px;
  min-height: 75px;
}

.modal-room-admin, .modal-kiosk-admin, .modal-mikvah-admin {
  .dropdown {
    .dropdown-item {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}

.modal-emergency{
  text-align: center;
  .emergency-icon{
    animation: flash ease-in-out 2s infinite;
  }
  .modal-content{
    svg{
      height: 120px;
      width: 120px;
      .cls-1, .cls-2{
        stroke: $main-color-red;
      }
    }
    h1{
      font-size: 55px;
      padding: 0px 0px 50px 0px;
    }
  }
}

.progress-reservations {
  height: 15px;
  border-radius: 25px;
  margin-bottom: 15px;
  .progress-bar-upcoming {
    background-color: $main-color-light-orange;
  }
  .progress-bar-here {
    background-color: $main-color-orange;
  }
  .progress-bar-completed {
    background-color: $main-color-grey;
  }
}

.progress-reservation {
  .progress {
    height: 15px;
    border-radius: 25px;
    .progress-bar {
      transition: width 0.3s ease;
      //border-radius: 25px;
      &.progress-bar-waiting { background-color: $gray-400; }
      &.progress-bar-assigned { background-color: $main-color-light-orange; }
      &.progress-bar-prep, &.progress-bar-dressing { background-color: $main-color-orange; }
      &.progress-bar-readyformikvah { background-color: $main-color-light-orange; }
      &.progress-bar-inmikvah { background-color: $main-color-blue; }
    }
  }
  .progress-supplies {
    position: relative;
    top: -10px;
    .progress-bar {
      background-color: $gray-200;
      border-radius: 25px;
      height: 5px;
      position: absolute;
    }
  }
  .points {
    font-size: 10px;
    color: $gray-500;
    height: 20px;
    .point {
      position: absolute;
      transform: translateX(-50%);
      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 4px;
        border-left: 1px solid $gray-400;
        left: 50%;
        top: 95%;
      }
    }
  }
  .hours {
    padding-top: 2px;
    font-size: 9px;
    line-height: 1;
    font-weight: 400;
    color: $gray-500;
    .hour {
      border: 0 solid $gray-400;
      border-left-width: 1px;
      position: absolute;
      padding: 0 2px;
      &:first-child, &:last-child {
        padding-top: 10px;
      }
      &:last-child {
          border-left: 0;
        border-right-width: 1px;
      }
    }
  }
}

.weather {
  text-align: end;
  .icon {
    font-family: Weather;
    font-size: 70px;
    font-weight: normal;
    margin-inline-end: 20px;
    display: inline-block;
    line-height: 1;
  }
  .temp {
    font-size: 70px;
    font-weight: 200;
    display: inline-block;
    line-height: 1;
    sup {
      top: -.25em;
    }
  }
  .description {
    font-size: 20px;
    font-weight: normal;
  }
}

.signalr-status {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  background-color: rgba(0,0,0,.6);
  color: #fff;
  padding: 10px 30px;
  border-radius: 8px;
  z-index: 9;
  font-weight: normal;
  transition: opacity .3s ease-in-out;
  .icon {
    margin-inline-end: 15px;
  }
  &.mode-null {
    display: none;
  }
  &.mode-connected {
    opacity: 0;
    pointer-events: none;
  }
  .btn {
    margin: -10px 0 -8px;
    color: #fff;
  }
  .loader-spinner {
    margin: -27px 0 0 -6px;
    position: absolute;
    svg {
      width: 32px;
      height: 32px;
    }
  }
}

.input-wrap {
  position: relative;
  .input-group-append {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding-inline-end: 15px;
  }
}

.loader {
  text-align: center;
}

svg {
  .cls-1, .cls-2 {
    stroke: $text-color-light;
  }
  .cls-3{
    fill: $text-color-light;
  }
}

.loader {
  text-align: center;
  svg {
    stroke: $text-color;
  }
}

.table td {
  font-size: inherit;
}

.row-sm {
  margin-inline-end: -5px;
  margin-inline-start: -5px;

  > .col,
  > [class*="col-"] {
    padding-right: 5px;
    padding-inline-start: 5px;
  }
}

.logo {
  max-width: 500px;
  padding: 0 20px;
  margin: 0 auto;
  svg {
    fill: $main-color-navy;
  }
}

.view-login {
  .container-login {
    margin: 0 auto;
    text-align: center;
    max-width: 400px;
    flex-grow: 1;
    .logo{
      max-width: 100%;
    }
  }
}

.version {
  position: fixed;
  padding: 5px;
  bottom: 0;
  right: 0;
  font-size: 10px;
  opacity: .5;
  line-height: 1;
}

body {
  text-align: start;
  .numeric-keyboard {
    width: 360px;
    height: auto;
    margin: 0 auto;
    font-size: 2.5em;
    direction: ltr;
    .numeric-keyboard-key {
      height: 80px;
      &[data-key=enter] {
        &::before {
          content: '';
          background: #f7f7f7;
          mask-image: url(./assets/images/icon-check.png);
          mask-repeat: no-repeat;
          mask-position: center;
          mask-size: contain;
          width: 50px;
          height: 50px;
          position: absolute;
          transform: translate(-50%,-50%);
        }
      }
    }
  }
}

