.modal-dialog {
  &.modal-full {
    width: calc(100% - (2 * #{$modal-dialog-margin-y-sm-up}));
    min-height: calc(100% - (2 * #{$modal-dialog-margin-y-sm-up}));
    display: flex;
    flex-direction: column;
    box-sizing: content-box;
    max-width: none;
    .modal-content {
      // padding: 65px 80px;
      flex-grow: 1;
      padding: 50px;
      height: 100%;
      .modal-header {
        border-bottom: none;
        padding: 0;
        .icon {
          width: 95px;
          height: 95px;
          margin-inline-end: 27px;
          flex-shrink: 0;
          .cls-1, .cls-2, .cls-3 {
            stroke: $main-color-light-orange;
          }
        }
        .close img{
          height: 50px;
          width: 50px
        }
        h4 {
          font-size: 55px;
          font-weight: 500;
          color: $text-color-light;
        }
        h5 {
          font-size: 24px;
          font-weight: 400;
        }
        .close {
          font-size: 45px;
        }
      }
      .modal-body {
        padding: 0;
      }
    }
  }
  .modal-content {
    box-shadow: 0 30px 60px -12px rgba(84, 84, 84, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3);
    border-color: transparent;
  }
  &.lang-he{
    .close{
      margin: -1rem auto -1rem -1rem;
    }
  }
}
.modal-backdrop {
  background-color: $text-color;
  &.show {
    opacity: .8;
  }
}


.modal-music {
  .modal-header {
    h4 {
      padding-top: 10px;
      padding-bottom: 12px;
    }
  }
  .modal-body {
    display: grid;
    grid-template-rows: 60vh;
    grid-template-columns: 4fr 1fr;
    align-items: center;
    .tracks {
      text-align: center;
      display: flex;
      justify-content: space-evenly;
      .music-option {
        width: 220px;
      }
    }
    .slider-volume{
      text-align: center;
      display: flex;
      flex-direction: column;
    }
    .btn-circle .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0px;
    }
    p {
      line-height: 1.3;
      margin: 0 auto;
      padding-top: 20px;
      font-size: 27px;
      font-weight: 500;
      color: $text-color-light;
    }
    .slider-volume {
      position: relative;
      .icon-volume {
        position: absolute;
        top: -48px;
        left: calc(50%);
        transform: translateX(-50%);
        font-size: 20px;
      }
      p {
        width: 40%;
      }
    }
    .btn-circle {
      height: 220px;
      width: 220px;
      margin: 0 auto;
      svg {
        transition: all .05s ease;
        height: 44px;
        color: white;
        width: 44px;
      }
      &.is-playing {
        svg {
          width: 56px;
          height: 56px;
        }
      }
    }
    .MuiSlider-root {
      display: flex;
      height: 220px;
      justify-content: center;
      margin: 0 auto;
      .MuiSlider-rail {
        width: 5px;
      }
      .MuiSlider-track {
        width: 5px;
        background-color: #4a90e2;
      }
      .MuiSlider-thumb {
        height: 33px;
        width: 33px;
        margin-inline-start: 0;
        background-color: #4a90e2;
      }
      .MuiSlider-valueLabel {
        left: 0;
      }
    }
  }
}
.modal-footer{
  border-top: none;
  justify-content: center;
}

.modal-supplies {
  .items {
    height: 53vh;
    display: flex;
    flex-wrap: wrap;
    .item {
      align-items: center;
      display: flex;
      width: 33%;
      height: 33%;
      p {
        font-size: 26px;
        font-weight: 500;
        padding-inline-start: 27px;
        margin-bottom: 0px;
      }
    }
  }
  .nex{
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translatey(-50%);
    img{
      transform: rotate(90deg);
      height: 60px;
    }
  }
  .prev{
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translatey(-50%);
    img{
      transform: rotate(270deg);
      height: 60px;
    }
  }
  .btn-circle {
    height: 110px;
    width: 110px;
    &.active {
      background: $main-color-light-orange;
    }
  }
  .requestButton {
    text-align: center;
  }
  .requestedBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 0px;
    }
    .requestedText {
      margin-top:auto;
      text-align: center;
    }
    .requestButtons {
      margin-top:auto;
      width: 65%;
      display: flex;
      justify-content: space-evenly;
    }
    .requestedTitle {
      font-size: 55px;
    }
    .requestedInfo {
      font-size: 24px;
      width: 65%;
      margin: 0 auto;
    }
  }
  &.lang-he{
    .prev{
      left: auto;
      right: 0px;
      img{
        transform: rotate(90deg);
      }
    }
    .nex{
      left: 0px;
      right: auto;
      img{
        transform: rotate(270deg);
      }
    }  
  }
}

.modal-help {
  .requestButton {
    text-align: center;
  }
  .requestedBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 0px;
    }
    .requestedText {
      margin-top:auto;
      text-align: center;
    }
    .requestButtons {
      margin-top:auto;
      width: 65%;
      display: flex;
      justify-content: space-evenly;
    }
    .requestedTitle {
      font-size: 55px;
    }
    .requestedInfo {
      font-size: 24px;
      width: 65%;
      margin: 0 auto;
    }
  }
}

.modal-timer {
  .modal-header {
    .icon {
      margin-inline-end: 27px;
    }
  }
}

.modal-language {
  display: flex;
  .modal-content {
    width: 80%;
    height: 80% !important;
    margin: auto auto;
  }
  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    svg {
      height: 66px;
      width: 66px;
    }
    h4 {
      font-size: 55px;
      font-weight: 500;
      color: $text-color-light;
    }
    .btn-lang-container {
      width: 90%;
      display: flex;
      justify-content: space-evenly;
    }
    .btn-lang {
      height: 110px;
      width: 236px;
      font-size: 20px;
      font-weight: 600;
      color: $text-color-light;
      margin-top: 50px;
    }
  }
}

.modal-checklist {
  .checklist-content {
    //columns: 3;
    //column-gap: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
    h4 {
      margin-bottom: 15px;
    }
  }
  .btn-checklist {
    padding: 0;
    margin-bottom: 10px;
    text-align: start;
  }

}

.modal-rabbonim{
  .rabbonim-content {
    margin-top: 45px;
    .rabbi-num{
      margin-bottom: 20px;
    }
  }

}

.slide-out-bck-br {
	-webkit-animation: slide-out-bck-br 0.4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-bck-br 0.4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.scale-down-br {
  -webkit-animation: scale-down-br 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: scale-down-br 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-1-7 17:4:36
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-bck-br
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-bck-br {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateY(1000px) translateX(1000px);
            transform: translateZ(-1100px) translateY(1000px) translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bck-br {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateY(1000px) translateX(1000px);
            transform: translateZ(-1100px) translateY(1000px) translateX(1000px);
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-1-8 9:17:54
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-down-br
 * ----------------------------------------
 */
 @-webkit-keyframes scale-down-br {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scale(0.05);
            transform: scale(0.05);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes scale-down-br {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scale(0.05);
            transform: scale(0.05);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}


