.btn {
  touch-action: manipulation;
  //border-radius: 4px;
  .loader-spinner {
    margin: -6px auto;
  }
  &:not(.btn-lg) {
    .loader {
      svg {
        margin: -6px auto;
        width: 22px;
        height: 22px;
      }
    }
  }
  .icon {
    svg {
      display: block;
    }
  }
}

.btn-circle {
	height: 110px;
	width: 110px;
	border: 2px solid #D4D4D4;
	background-color: $main-color-lightgrey;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.1);
  border-radius: 50%;
  .btn-inner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  color: $main-color-grey;
  font-size: 27px;
  font-weight: normal;
  transition: all .2s ease;
  background-size: contain;
  .icon {
    width: 42px;
    height: 42px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &.btn-lg {
    height: 220px;
    width: 220px;
    background-color: #ffffff;
  }
  &.active {
    background-color: $text-color-light;
    svg {
      color: $main-color-light-orange;
      .cls-1, .cls-2 {
        stroke: $main-color-light-orange;
      }
    }
  }
  &:focus {
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.1);
  }
  &:active {
    //background-color: #eee;
    box-shadow: 0 0px 0px 0 rgba(0,0,0,0.1);
  }
}

.btn-circle-big,.btn-circle-medium {
  margin: 0 auto;
  height: 300px;
  width: 300px;
  background: linear-gradient(180deg, #BFC4CA 0%, #E9EEF2 100%);
  box-shadow: inset 0 2px 9px 0 rgba(0,0,0,0.05), 0 2px 0 0 rgba(255,255,255,0.75);
  box-sizing: border-box;
  padding: 10px;
  line-height: 1;
  font-size: 46px;
  font-weight: 600;
  border-radius: 50%;
  text-transform: uppercase;
  &.btn-circle-medium{
    height: 250px;
    width: 250px;
    font-size: 36px;  
  }
  .btn-inner {
    box-sizing: border-box;
    background: linear-gradient(180deg, #EBD8CA 0%, #F1F6FA 100%);
    width: 100%;
    height: 100%;
    border: 3px solid rgba(0,0,0,0.05);
    box-shadow: inset 0 -9px 0 0 rgba(0,0,0,0.1), inset 0 3px 3px 0 rgba(255,255,255,0.5), 0 0 14px 0 rgba(0,0,0,0.1), 0 17px 11px 0 rgba(0,0,0,0.15), 0 3px 6px 0 rgba(0,0,0,0.25);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 30px;
    color: $text-color-light;
    background-size: 280px 280px;
    background-position: 0;
    svg {
      width: 64px;
      height: 64px;
      margin-bottom: 20px;
    }
    .loader-spinner {
      svg {
        width: 100px;
        height: 100px;
        margin-bottom: 0;
        stroke: $main-color-grey;
      }
    }
  }
}

.btn-outline-primary {
}

.btn-outline-secondary {
  color: $text-color;
}

.btn-lg {
  text-transform: uppercase;
  min-width: 300px;
}

.btn-link {
  font-weight: 600;
  text-transform: uppercase;
  @include hover-focus {
    text-decoration: none;
  }
}

.loader-spinner {
  svg {
    &, .btn-secondary &, .btn-outline-primary & {
      stroke: $main-color-grey;
    }
  }
  &.dark {
    svg {
      stroke: $main-color-grey;
    }
  }
  &.large {
    svg {
      width: 80px;
      height: 80px;
    }
  }
}
