$form-check-input-width:                  1.25em !default;
$form-check-min-height:                   $font-size-base * $line-height-base !default;
$form-check-padding-left:                 $form-check-input-width + .5em !default;
$form-check-margin-bottom:                .125rem !default;

$form-check-input-active-filter:          brightness(90%) !default;

$form-check-input-bg:                     $body-bg !default;
$form-check-input-border:                 1px solid rgba(0, 0, 0, .25) !default;
$form-check-input-border-radius:          .25em !default;
$form-check-radio-border-radius:          50% !default;
$form-check-input-focus-border:           $input-focus-border-color !default;
$form-check-input-focus-box-shadow:       $input-btn-focus-box-shadow !default;

$form-check-input-checked-color:          $component-active-color !default;
$form-check-input-checked-bg-color:       $component-active-bg !default;
$form-check-input-checked-border-color:   $form-check-input-checked-bg-color !default;
$form-check-input-checked-bg-repeat:      no-repeat !default;
$form-check-input-checked-bg-position:    center center !default;
$form-check-input-checked-bg-size:        1em !default;
$form-check-input-checked-bg-image:       str-replace(url('data:image/svg+xml;utf8,<svg viewbox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path stroke="#{$form-check-input-checked-color}" stroke-width="3" d="M4 8.5L6.5 11l6-6" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>'), "#", "%23") !default;
$form-check-radio-checked-bg-image: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$form-check-input-checked-color}'/%3e%3c/svg%3e"), "#", "%23") !default;

$form-check-input-indeterminate-color:          $component-active-color !default;
$form-check-input-indeterminate-bg-color:       $component-active-bg !default;
$form-check-input-indeterminate-border-color:   $form-check-input-indeterminate-bg-color !default;
$form-check-input-indeterminate-bg-repeat:      no-repeat !default;
$form-check-input-indeterminate-bg-position:    center center !default;
$form-check-input-indeterminate-bg-size:        1em !default;
$form-check-input-indeterminate-bg-image:       str-replace(url('data:image/svg+xml;utf8,<svg viewbox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M5 8h6" stroke="#{$form-check-input-indeterminate-color}" stroke-width="3" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>'), "#", "%23") !default;

$form-switch-color:               rgba(0, 0, 0, .25) !default;
$form-switch-width:               2em !default;
$form-switch-height:              $form-check-input-width !default;
$form-switch-padding-left:        $form-switch-width + .5em !default;
$form-switch-bg-image:            str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$form-switch-color}'/%3e%3c/svg%3e"), "#", "%23") !default;
$form-switch-border-radius:       $form-switch-width !default;
$form-switch-transition:          .2s ease-in-out !default;
$form-switch-transition-property: background-position, background-color !default;

$form-switch-focus-color:         hsla(211, 100%, 75%, 1) !default;
$form-switch-focus-bg-image:      str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$form-switch-focus-color}'/%3e%3c/svg%3e"), "#", "%23") !default;

$form-switch-checked-color:       $component-active-color !default;
$form-switch-checked-bg-image:      str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$form-switch-checked-color}'/%3e%3c/svg%3e"), "#", "%23") !default;
$form-switch-checked-bg-position:  right center !default;


$form-check-inline-margin-inline-end:        1rem !default;

$form-check-input-margin-x:             .25rem !default;




//
// Check/radio
//

.form-check {
  display: block;
  min-height: $form-check-min-height;
  padding-inline-start: $form-check-padding-left;
  margin-bottom: $form-check-margin-bottom;
}

.form-check-input {
  float: left;
  width: $form-check-input-width;
  height: $form-check-input-width;
  // Todo: Change static value to base line-height?
  margin-top: calc((1.5em - #{$form-check-input-width}) / 2); // line-height minus check height
  margin-inline-start: $form-check-padding-left * -1;
  background-color: $form-check-input-bg;
  border: $form-check-input-border;
  appearance: none;

  &[type="checkbox"] {
    @include border-radius($form-check-input-border-radius);
  }

  &[type="radio"] {
    @include border-radius($form-check-radio-border-radius);
  }

  &:active {
    filter: $form-check-input-active-filter;
  }

  &:focus {
    border-color: $form-check-input-focus-border;
    outline: 0;
    box-shadow: $form-check-input-focus-box-shadow;
  }

  &:checked {
    background-color: $form-check-input-checked-bg-color;
    background-repeat: $form-check-input-checked-bg-repeat;
    background-position: $form-check-input-checked-bg-position;
    background-size: $form-check-input-checked-bg-size;
    border-color: $form-check-input-checked-border-color;

    &[type="checkbox"] {
      background-image: $form-check-input-checked-bg-image;
    }

    &[type="radio"] {
      background-image: $form-check-radio-checked-bg-image;
    }
  }

  &[type="checkbox"]:indeterminate {
    background-color: $form-check-input-indeterminate-bg-color;
    background-image: $form-check-input-indeterminate-bg-image;
    background-repeat: $form-check-input-indeterminate-bg-repeat;
    background-position: $form-check-input-indeterminate-bg-position;
    background-size: $form-check-input-indeterminate-bg-size;
    border-color: $form-check-input-indeterminate-border-color;
  }

  // Use disabled attribute instead of :disabled pseudo-class
  // Workaround for: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11295231
  &[disabled] {
    pointer-events: none;
    filter: none;
    opacity: .5;

    ~ .form-check-label {
      opacity: .5;
    }
  }
}

.form-check-label {
  margin-bottom: 0;
}

//
// Switch
//

.form-switch {
  padding-inline-start: $form-switch-padding-left;

  .form-check-input {
    width: $form-switch-width;
    margin-inline-start: $form-switch-padding-left * -1;
    background-image: $form-switch-bg-image;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: calc(#{$form-switch-height} - 2px); // Get a 1px separation
    @include border-radius($form-switch-border-radius, $form-switch-border-radius);
    // Todo: Figure out how to tackle these, with or without mixin?
    // transition: $form-switch-transition;
    // transition-property: $form-switch-transition-property;

    &:focus {
      background-image: $form-switch-focus-bg-image;
    }

    &:checked {
      background-image: $form-switch-checked-bg-image;
      background-position: $form-switch-checked-bg-position;
    }
  }
}

.form-check-inline {
  display: inline-block;
  margin-inline-end: $form-check-inline-margin-inline-end;
}