.view-manager{
  .container-main{
    background-color: rgba($main-color-light-orange, .2) !important;
  }
  .manager-background{
    height: calc(100vh - #{$reception-header-height});
    .dropdown-menu{
      max-height: 500px;
      overflow: auto;
    }
  }
  .usage-summary{
    max-width: 400px;
    border-radius: 6px;
    padding: 20px;
    margin-inline-start: auto;
    margin-inline-end: auto;
    height: 100%;
    font-weight: 500;
    letter-spacing: .8px;
    margin-bottom: 7px;
    list-style: none;
    .divider{
      border-bottom: 2px dashed #ebd8ca;
      padding-bottom: 7px;
    }
    li{
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;
    }
  }
  .remove-product{
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .manager-dash{
    h2{
      margin-bottom: 40px;
    }
    .manager-tools{
      margin-bottom: 100px;
    }
    .btn{
      background-color: $text-color-light;
      border-radius: 0;
      color: #FFF;
      width: 100%;
      font-size: 20px;
      text-align: start;
      letter-spacing: 1px;
      padding: 25px 35px;
      margin-bottom: 26px;
      display: flex;
      align-items: center;
      svg{
        margin-inline-end: 20px;
        height: 25px;
        width: auto;
      }
    }
  }
}