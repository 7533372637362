
.flippers {
  display: flex;
  direction: ltr;
  .flipper:not(:last-child) {
    margin-inline-end: 4px;
  }
}

.flipper {
  border: 1px solid #EBD8CA;
  border-radius: 6px;
  background: #fff;
  font-size: 50px;
  font-weight: normal;
  display: flex;
  height: 68px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
  position: relative;
  text-transform: uppercase;
  &.single {
    width: 45px;
  }
  &.double {
    width: 90px;
  }
  .room-status & {
    font-weight: bold;
    font-size: 35px;
    .dropdown & {
      cursor: pointer;
    }
  }

  .text {
    position: relative;
    line-height: 1;
    &.active {
      color: white;
    }
  }
}