.view-attendant-tablet {
  .roomType{
    text-transform: uppercase;
  }
  .header {
    @media (max-width: 450px) {
      padding: 20px 10px;    
    }      
    .header-left {
      max-width: 41.66667% !important;
      .zman-tevilah{
        @media (max-width: 450px) {
          text-align: center;
        }      
        .title{
          @media (max-width: 450px) {
            margin-inline-end: 0px
          }        
        }
      }
    }
    .header-right {
      max-width: 58.33333% !important;
      .clock{
        @media (max-width: 450px) {
          font-size: 55px;
          letter-spacing: -5px;
        }      
        .small{
          @media (max-width: 450px) {
            font-size: 16px;
          }        
        }
      }
      .full-date{
        @media (max-width: 450px) {
          font-size: 20px;
        }      
      }
    }
  }
  h3{
    @media (max-width: 450px) {
      font-size: 20px;
    }  
  }
}

.view-mikvah {
  .header{
    width: 20%;
    padding: 65px 15px 40px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: -5px 0px 36px 0px rgba(0,0,0,.3);
    z-index: 2;    
    .header-top{
      text-align: start;
      padding-bottom: 50px;
      .clock{
        font-size: 65px;
        margin-bottom: 15px;
        letter-spacing: -4px;
        text-align: start;
      }
    }
    .header-zman{
      font-size: 19px;
      padding-bottom: 25px;
    }
    .header-summary{
      .room-availability{
        h3{
          display: none;
        }
        .items{
          flex-direction: column;
          > div{
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;
            .title{
              margin-bottom: 0px;
              font-size: 18px;
            }
            .status{
              margin-inline-end: 15px;
              border-radius:25px;
              font-size: 24px;
              width: 75px;
            }
          }
        }
      }
    }
  }
  .btn-reveal {
    display: block;
    margin: 0 auto;
    svg {
      width: 66px;
      height: 66px;
    }
  }
  .attendant-list{
    height: 100%;
    display: flex;
    flex-direction: column;
    .leave-mikvah{
      flex-grow: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .room-in-mikvah{
        font-size: 32px;
        padding-bottom: 10px;
      }
    }
  }
}
.container-page.view-attendant-sectionmap {
  display: grid;
  header.header {
    .room-availability{
      margin-inline-start: auto;
      margin-inline-end: auto;
      width: 100%;
      h3{
        text-align: center;
      }
    }
    .clock {
      font-size: 80px;
      margin-bottom: 10px;
    }
  }
  .sectionmap-container{
    display: flex;
    height: 100%;
    .attendant-list.container-body{
      width: 36%;
      flex-grow: unset;
    }
    .container-map{
      padding: 15px;
      width: 100%;
    }
    .active-container-map{
      width: 61%;
    }
  }
  .attendant-list.container-body{
    .sidebar-top > div{
      padding: 15px;
    }
  }
}

.container-page.view-all-tasks{
  .all-task-zmanim{
    .closing-time{
      font-size: 40px;
    }    
    .zman-tevilah{
      font-size: 40px;
    }
  }
  .res-overview{
    font-size: 24px;
    .progress-reservations{
      .progress-bar-upcoming{
        background-color: #FFF;
      } 
    }
    .num{
      background-color: #FFF;
      padding: 0px 20px;
      border-radius: 16.5px;
    }
  }
  .room-availability{
    margin-bottom: 15px;
    .title{
      font-size: 22px;
    }
    .status{
      font-size: 28px;
      width: 70px;
      border-radius: 25px;
    }
  }
  .sidebar-top .table th{
    font-size: 26px !important;
  }
  .roomsPrep{
    font-weight: bold !important;
    font-size: 32px !important;  }
}

.attendant-list{
  &.sections{
    .col{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      border-bottom: 2px solid #FFF;
      background-color: $main-color-lightgrey;
      color: rgba($text-color,0.6);
      &.currentSection{
        color: rgba($text-color,1);
      }
      &.active{
        background-color: $text-color-light;
        color: $main-color-light-orange;
      }
    }
    .col-all{
      font-size: 24px;
      border-bottom: none;
      color: rgba($text-color,1);
    }
  }
  .table{
    th{
      text-align: start !important;
    }
    tr{
      border-bottom: 1px solid $text-color-light;
      td{
        text-align: start !important;
        .icon-excluded{
          height: 24px;
          display: inline;
          div{
            display: inline;
          }
          svg{
            height: 24px;
            display: inline;
            .cls-1{
              fill: $text-color;
              stroke: $text-color;
            }
          }
        }  
      }
    }
  }

}

.collapse-container {
  border-bottom: 2px solid $main-color-grey;
  &:first-child {
    border-top: 2px solid $main-color-grey;
  }
  .collapse-header {
    padding: 20px 0;
    display: flex;
    align-items: center;
    padding-inline-start: 40px;
    position: relative;
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
      background: fade-out($color: $main-color-light-orange, $amount: .7);
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 14px;
      height: 16px;
      background: url(assets/images/icon-minus.svg) no-repeat;
      background-size: contain;
    }
    &.collapsed::before {
      background: url(assets/images/icon-plus.svg) no-repeat;
    }
    .title {
      display: flex;
      color: $text-color-light;
      font-size: 18px;
      text-transform: uppercase;
    }
    .meta {
      margin-inline-start: auto;
      display: flex;
      > div {
        margin-inline-start: 20px;
        .label {
          font-size: 12px;
          font-weight: bold;
          color: $text-color-light;
        }
      }
    }
  }
  .collapse-body {
    font-weight: normal;
  }
}

.lang-he{
  .collapse-header{
    &::before {
      left: unset;
      right: 0;
    }
  }
}


.over-time {
  color: $main-color-red;
  animation: flash ease-in-out 2s infinite;
}


.view-tablet {
  .container-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    .sidebar-top {
      overflow-y: scroll;
      flex-grow: 1;
      position: relative;
      .alert{
        @media (max-width: 450px) {
          font-size: 14px;
        }      
      }
      > div {
        position: absolute;
        width: 100%;
        padding: 40px;
        @media (max-width: 450px) {
          padding: 10px;
        }
      }
      .table {
        @extend .table-borderless;
        border-top: 2px solid $main-color-grey;
        th, td {
          &:first-child {
            padding-inline-start: 0;
          }
          &:last-child {
            padding-inline-end: 0;
          }
        }
        th {
          color: $text-color-light;
          font-weight: bold;
          font-size: 18px;
          text-align: center;
          @media (max-width: 450px) {
            font-size: 14px;
          }  
        }
        td {
          text-align: center;
          font-size: 24px;
          font-weight: normal;
          vertical-align: middle;
          @media (max-width: 450px) {
            font-size: 16px;
          }
          &.section-number{
            font-size: 55px;
            @media (max-width: 450px) {
              font-size: 40px;
            }    
          }  
          &.room-number {
            font-size: 55px;
            svg{
              height: 35px;
              margin-inline-start: 10px;
            }
            @media (max-width: 450px) {
              font-size: 40px;
            }    
            .roomNumberTag {
              text-transform: uppercase;
              margin-bottom: -15px;
              font-size: 20px;
              @media (max-width: 450px) {
                font-size: 14px;
              }      
            }
          }
        }
        .mikvahNext {
          background: white;
          .room-number {
            color: $main-color-blue;
          }
          .section-number {
            color: $main-color-blue;
          }
        }
      }
      .title {
        text-align: center;
      }
    }
    .sidebar-bottom {
      padding: 40px;
      padding-top: 0;
      @media (max-width: 450px) {
        padding: 10px;
      }    
      .table {
        @extend .table-sm;
        th {
          font-size: 18px;
          text-align: center;
          padding-top: $table-cell-padding;
        }
        td {
          text-align: center;
          .status {
            font-size: 22px;
            width: 70px;
            border-radius: 50px;
          }
        }
      }
      .room-availability {
        .items {
          width: 65%;
          @media (max-width: 450px) {
            width: 100%;
          }      
        }
        .title {
          font-size: 18px;
          margin-bottom: 10px;
          @media (max-width: 450px) {
            font-size: 14px;
          }   
        }
        .status {
          font-size: 22px;
          width: 70px;
          border-radius: 50px;
          @media (max-width: 450px) {
            font-size: 14px;
            width: 45px;
          }        
        }
      }
      .reservation-count {
        margin: 0 auto;
        margin-top: 30px;
        text-align: center;
        width: 65%;
        @media (max-width: 450px) {
          width: 100%;
          margin-top: 20px;
        }      
        .title {
          margin: 7px auto;
          font-size: 20px;
        }
        .progress-reservations {
          margin: 0 auto;
          height: 33px;
        }
        .col {
          font-size: 18px;
          @media (max-width: 450px) {
            font-size: 14px;
          }  
        }
      }
    }
  }  
}


