.view-waiting-room {
  .container-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .room-reservation {
      display: flex;
      justify-content: center;
      width: 33%;
      text-align: center;
      margin-bottom: 30px;
      @media (min-width: 1600px) {
        margin-bottom: 60px; 
      }
      .reservation-code {
        width: 270px;
      }
      .room-number {
        margin-inline-start: 20px; 
        @media (min-width: 1600px) {
          margin-inline-start: 60px; 
        }
        width: 140px;
      }
      .title {
        margin-bottom: 15px;
        font-size: 18px;
        @media (min-width: 1600px) {
          margin-bottom: 30px;
          font-size: 24px;
        }
      }
      .flipper {
        margin-top: 18px;
        margin-bottom: 18px;
      }
      .flippers {
        transform: scale(1.3);
        @media (min-width: 1600px) {
          transform: scale(1.5);          
        }
        display: inline-flex;
        &.active {
          .flipper {
            background-color: $main-color-green;
          }
        }
      }
      &:nth-child(n+10) {
        @media (max-width: 1600px) {
          display: none;
        }
      }
    }
  }
}