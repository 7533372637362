$sizeW: 500px;
$sizeH: 235px;

.screen-saver {
  direction: ltr;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  z-index: 999999;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  &.show {
    opacity: 1;
    pointer-events: initial;
  }
  .x {
    width: $sizeW;
    animation: x 13s linear infinite alternate;
  }
  
  .y {
    width: $sizeW;
    animation: y 7s linear infinite alternate;
  }

  .logo {
    padding: 0;
    svg {
      fill: $gray-100;
    }
  }

  .text {
    color: #fff;
    font-size: 1.4rem;
    font-weight: normal;
    margin-top: $spacer * 2;
    text-transform: uppercase;
  }
  
  @keyframes x {
    100% {
      transform: translateX( calc(100vw - #{$sizeW}) );
    }
  }
  
  @keyframes y {
    100% {
      transform: translateY( calc(100vh - #{$sizeH}) );
    }
  }
}
